$initial-primary-color: #48a272;

:root {
  --primary-color: #48a272;
  --secondary-color: #17253f;
  --link-color: #54be85;
  --header-text-color: #ffffff;
  --primary-text-color: #17253f;
  --tooltip-primary-color: #17253f;
}

//**** fonts ****//

$font-primary: 'Muli', 'Source Sans Pro', sans-serif;

//**** colors ****//

// $brand-primary: #48A272; /* accents */
// $brand-secondary: #17253F; /* header */

$color-link: var(--link-color); /* all links */
$color-header-text: var(--header-text-color); /* all links */
$color-modal-background: rgba(23, 37, 63, 0.6);

/* Charts, Avatar backgrounds, icons */
$color-red: #e05263;
$color-yellow: #ffb30f;
$color-blue: #73d2de;
$color-green: #54be85;

$color-off-white: #fbfdff; /* Tbl row striped */
$color-white-dark: #f8fbff; /* Tbl row selected, Dropdown hover */
$color-white-darker: #e9edf1; /* Borders, Tbl header, light icons */

$color-gray-lightest: #dadada; /* Button borders, checkbox borders, alerts */
$color-gray-light: #c3c6c9; /* Placeholder text */
$color-gray: #858c92; /* Light text, labels */
$color-gray-dark: #2c3444; /* text color */

$color-text: $color-gray-dark;

$color-white: #ffffff;
$color-black: #000000;

$background: #f9fafb;

/* actions */
$color-error: $color-red;
$color-warning: $color-yellow;
$color-success: var(--primary-color);
