@import '../../styles/main.scss';

.itemClose {
  position: absolute;
  right: 0;
  cursor: pointer;

  &[disabled] {
    color: $color-gray;
    cursor: default;
  }
}

.modalClose {
  position: relative;
  cursor: pointer;

  &[disabled] {
    color: $color-gray;
    cursor: default;
  }
}

.inputUnit {
  position: absolute;
  z-index: 0;
  top: -12px;
  left: 0;
  padding: 11px;
  background-color: $color-off-white;
  border-right: 1px solid $color-white-darker;
}

.arrow_upward {
  color: rgb(168, 89, 69);
}

.arrow_downward {
  color: rgb(59, 88, 73);
}

.warning {
  color: $color-warning;
  padding-right: 5px;
}

.infoTooltip {
  cursor: pointer;
}
