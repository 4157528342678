.tabs {
  width: 100%;
  padding-top: 0;
  border-bottom: 1px solid $color-white-darker;
  background-color: $color-white;
  text-align: left;
  @media (min-device-width: 1024px) {
    padding-top: 20px;
  }
}

.tab {
  position: relative;
  display: inline-block;
  margin-right: 1em;
  padding-bottom: 25px;
  color: $color-gray;
  font-weight: 500;
  transition: color 200ms;
  cursor: pointer;
  @media (min-device-width: 1024px) {
    margin-right: 30px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: $color-text;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
  }

  &Active {
    color: $color-text;

    &:after {
      background-color: var(--primary-color);
    }
  }

  &Inactive {
    display: none;
  }
}
