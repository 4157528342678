//**** containers ****//

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.containerSmall {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 15px;
}

.sidePadding {
  padding: 0 25px;
}

//**** calculations ****//

$minusHeader: calc(100vh - 109px);
