@import '../../../../../../styles/main.scss';

.projectContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 15px;
    border: 2px solid $color-gray-dark;
    border-radius: 5px;
    position: relative;
    margin: 15px 0px;
    width: 100%;
    &Close {
        position: absolute;
        right: 5px;
    }
    &Collapsible {
        position: absolute;
        right: 35px;
    }
    &CollapsibleSection {
        margin-top: 10px;
        height: auto;
        transition: height 200ms;
    }
    &Header{
        display: flex;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        align-items: center;
        gap: 5px;
        max-width: 95%;
    }
}

.hide {
    display: none;
}