@import '../../styles/main.scss';

.chartError {
  width: 100%;
  height: 100%;
  padding: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &Title {
    font-weight: bold;
    font-size: 24px;
  }

  &Description {
    margin-top: 12px;
    font-size: 16px;
  }
}
