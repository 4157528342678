.extras {
  position: relative;
  background-color: $color-white;
  font-size: 16px;

  &Button {
    display: block;
    width: 60px;
    height: 38px;
    padding: 0 17px;
    border-radius: 2px;
    border: 2px solid $color-gray-lightest;
    transition: color 200ms ease-in-out, border 200ms ease-in-out;
    cursor: pointer;

    &:hover {
      color: var(--link-color);
      border: 2px solid var(--link-color);
    }

    &:after {
      /* dots */
      content: '\e5d3';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-family: 'Material Icons';
      font-style: normal;
      font-size: 140%;
    }
  }

  &Hide {
    .extrasDropdown {
      display: none;
    }
  }

  &Dropdown {
    position: absolute;
    z-index: 20;
    top: calc(100% - 2px);
    background-color: $color-white;
    box-shadow: 0px 1px 8px 0px $color-gray;

    &Left {
      left: 0;
      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        height: 10px;
        width: 100%;
      }
    }

    &Right {
      right: 0;
      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        height: 10px;
        width: 100%;
      }
    }

    &Up {
      top: 10;
    }

    @media (min-width: 600px) {
      width: 320px;
    }
  }

  &DropdownSurvey {
    @media (min-width: 375px) {
      width: 350px !important;
    }
  }

  &Count {
    display: inline-block;
    margin-left: auto;
  }

  &Link {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    transition: color 200ms;
    cursor: pointer;
    text-align: right;
    a {
      color: inherit;
      text-decoration: none;
    }

    i {
      margin-right: 10px;
      color: $color-gray-lightest;
      transition: color 200ms;
    }

    &:hover {
      color: var(--link-color);

      i {
        color: var(--link-color);
      }
    }

    &Inactive {
      color: $color-gray;
    }

    &Active {
      color: $color-text;
    }
  }

  &Left {
    text-align: left !important;
  }

  &InlineLink {
    display: inline-block;
    align-items: center;
    cursor: pointer;
    color: var(--link-color);

    i {
      margin-right: 10px;
      color: $color-gray-lightest;
      transition: color 200ms;
    }

    &:hover {
      i {
        color: var(--link-color);
      }
    }

    &Inactive {
      color: $color-gray;
    }

    &Active {
      color: $color-text;
    }
  }
}
