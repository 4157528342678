@import '../../styles/main.scss';
@import '../../styles/components/extras.scss';

.buildingHeader {
  padding: 20px 0;
  text-align: left;
  background-color: $color-white;

  @media (max-width: 400px) {
    padding: 20px 0 25px;
  }

  &Back {
    display: inline-block;
    margin-bottom: 30px;
    padding-top: 10px;
    text-align: left;
    font-size: 14px;
    color: $color-gray;
    cursor: pointer;

    i {
      margin-right: 10px;
      animation-duration: 1000ms;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      transition: 300ms;
    }

    &:hover {
      i {
        animation-name: bounce;
      }
    }

    @keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateX(0);
      }
      40% {
        transform: translateX(-6px);
      }
      60% {
        transform: translateX(-2px);
      }
    }
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 530px) {
      flex-wrap: wrap;
    }
  }

  .display {
    display: flex;
    align-items: flex-start;
    padding-right: 20px;
    text-align: left;

    h1 {
      width: 100%;
      margin: 0 0 10px;
      font-size: 24px;
      @media (min-width: 800px) {
        font-size: 32px;
      }
    }
  }

  .image {
    display: flex;
    max-width: 100px;
    height: 60px;
    margin-right: 20px;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    i {
      font-size: 40px;
      color: var(--primary-text-color);
    }

    @media (min-width: 500px) {
      max-width: 200px;
      height: 100px;
      margin-right: 40px;
    }
  }

  .detail {
    display: inline-block;
    margin: 0;
    color: $color-gray;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-end;

    @media (min-width: 500px) {
      width: 260px;
    }

    button {
      width: auto;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      @media (max-width: 400px) {
        font-size: 12px;
      }
    }
  }

  //   .extras {

  //     &Dropdown {
  //       width: 160px;
  //     }

  //     &Button {
  //       &:after {
  //         left: 18px;
  //       }
  //     }

  //     @media (min-width: 850px) {
  //       display: none;
  //     }

  //   }
}
.centerAlign {
  display: flex;
  align-items: center;
}
