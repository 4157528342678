@import '../../../../../styles/main.scss';

.chartContainer {
  p {
    margin: 0px 20px;
    color: $color-gray;
    font-size: 14px;
    align-self: center;
  }
}
