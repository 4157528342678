@import '../../styles/main.scss';
@import '../../styles/components/extras.scss';
@import '../../styles/components/tabs.scss';
@import '../../styles/components/empty.scss';

.portfolioContainer {
  &Loading {
    position: fixed;
    bottom: 120px;
    left: 0px;
    right: 0px;
    width: 100%;
    font-size: 40px;
    text-align: center;
    z-index: 11;
  }

  &Button {
    width: auto !important;
  }

  &Header {
    background-color: $color-white;

    .container {
      @media (min-width: 550px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &SecondHeader {
    background-color: $color-white;
    border-bottom: 1px solid $color-white-darker;
    margin-bottom: 30px;
    .container {
      padding: 0px 5px !important;
    }
  }
}

.dashboardChart {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  padding-bottom: 30px;

  .cardsColumn {
    display: flex;
    flex-direction: column;
    width: 250px;
    row-gap: 10px;
    @media (max-width: 700px) {
      width: 100%;
    }
  }
  .chartColumn {
    display: flex;
    flex-direction: column;
    width: calc(100% - 260px);
    height: 468px;
    border: 1px solid $color-white-darker;
    background-color: $color-white;
    border-radius: 4px;
    padding: 1px;
    @media (max-width: 700px) {
      width: 100%;
    }
  }
}

.timeRangeFilter {
  display: flex;
  flex: 0;
  padding: 10px;
  align-items: center;
  border: 2px solid $color-gray-lightest;
  border-radius: 30px;
  color: $color-black;
  flex-direction: row-reverse;
  font-size: 12px;
  cursor: pointer;
  margin: 5px;
  column-gap: 5px;

  &.noMargin {
    margin: 0;
  }

  &.marginLeft {
    margin: 0 0 0 10px;
  }
}

.portfolioTabs {
  height: 64px;
  overflow: hidden;
  justify-content: normal;
  @media (min-width: 550px) {
    display: flex;
    justify-content: normal;
    align-items: center;
  }
  .tabs {
    display: flex;
    white-space: nowrap;
  }

  .container {
    min-width: 570px;
  }
}

.panel {
  width: 100%;
  border: 1px solid $color-white-darker;
  background-color: #fff;
  text-align: left;
  &Filter {
    min-height: 66px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    &Container {
      padding: 5px 0;
    }
  }
}

.panelChart {
  width: 100%;
  background-color: #fff;
  text-align: left;
}
.buildingButtonsAdd {
  position: relative;
}
.filterSelect {
  border: 2px solid $color-gray-lightest;
  border-radius: 5px;
  padding: 8px 14px;
  cursor: pointer;
  &:hover {
    color: var(--link-color);
    border: 2px solid var(--link-color);
  }
}

.panelShowFilter {
  display: flex;
  font-size: 13px;
  padding: 0 17px;
  position: relative;
  > span {
    padding-top: 16px;
    min-width: 50px;
  }
}

.showFilterContainer {
  display: flex;
  flex-flow: row wrap;
  min-height: 47px;
}

.addFilter {
  padding: 7px 17px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 13px !important;
  align-items: center;
  display: flex;
  position: relative;
}

.showFilterSelect {
  position: relative;
  background-color: $color-white-darker;
  border: 1px solid $color-gray-lightest;
  border-radius: 30px;
  padding: 7px 17px;
  margin-left: 10px;
  margin: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &Disable {
    opacity: 0.65;
  }
}

.deleteIcon {
  font-size: 16px !important;
  background-color: #bfc5c9;
  color: $color-white;
  border-radius: 50%;
  padding: 0.2em;
  cursor: pointer;
  &:hover {
    background-color: lighten($color-gray, 20%);
  }
  &.buttonDisable {
    background-color: lighten($color-gray, 20%);
  }
}

.extrasButton {
  padding: 7px 14px 9px 17px !important;
}

.timeRange {
  border: 2px solid $color-gray-lightest;
  border-radius: 5px;
  padding: 8px 14px 8px 16px;
  cursor: pointer;
}

.searchFilter {
  position: relative;
  flex: 2;
  margin: 10px;
  max-width: 500px;
  input {
    padding: 12px 10px 12px 50px;
    ::placeholder {
      color: $color-gray-dark;
    }
  }

  i {
    /* eyeglass icon */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    color: $color-gray-dark;
  }

  @media (min-width: 800px) {
    // margin-top: 0;
  }
}

.projects {
  display: flex;
  @media (max-width: 1100px) {
    display: block;
  }
}

.buildingList {
  &Image {
    margin-right: 10px;
    border-radius: 2px;
    display: flex;
    align-content: center;

    img {
      width: 70px;
      height: 36px;
      object-fit: cover;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 70px;
      height: 36px;
      background-color: $color-white;
      i {
        color: $color-gray-light;
      }

      @media (min-width: 700px) {
        border: 1px solid $color-white-darker;
      }
    }
    @media (min-width: 700px) {
      // width: 70px;
      margin-right: 20px;
    }
  }

  &Info {
    width: calc(100% - 100px);
  }

  &Name {
    span {
      display: block;
      color: var(--link-color);
      font-size: 14px;

      &:last-child {
        color: $color-gray;
        font-size: 13px;
      }
    }
  }
}

.filterButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  .radioContainer {
    margin-bottom: 20px;
  }
}

.dropDown {
  position: relative;
}

.extrasButton {
  margin: 10px 0;
}

.loadingBuilding {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  width: 100%;
  font-size: 40px;
  text-align: center;
  div {
    background-color: $color-white;
    box-shadow: 0 0 7px 2px #888;
    padding: 20px;
    width: 300px;
    font-size: 14px;
    color: var(--primary-color);
  }
}

.collapse {
  padding-top: 16px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 15px;
  &Container {
    height: 47px;
  }
}

.disable {
  pointer-events: none;
  opacity: 0.7;
}

.cursorWait {
  cursor: wait;
}

.divider {
  display: inline;
  border: 1px solid $color-gray;
  margin-right: 1em;
  @media (min-width: 1024px) {
    margin-right: 30px;
  }
}

.scenarioName {
  color: var(--primary-color);
  cursor: pointer;
}

.cursorPointer {
  cursor: pointer;
}

.moreContainer {
  margin: 10px;
}

.tableFooterTotal {
  justify-content: space-between;
  color: $color-gray-dark;
}

.export {
  text-decoration: none;
  cursor: pointer;
  color: $color-gray-dark;
  display: flex;
  align-items: center;
  &:hover {
    color: var(--link-color);
  }
}
.projectPackageTab {
  margin-bottom: 10px;
}
.empty {
  margin-top: 100px;
  &Buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    button {
      width: auto;
    }
    button + button {
      margin-left: 100px;
    }
  }
}
.dashboard {
  margin-top: -60px;
  margin-bottom: 30px;
  &OneSubTab {
    margin-bottom: 65px !important;
  }

  &FilterButtons {
    padding: 14px 10px 14px 10px !important;
  }

  &Tab {
    font-size: 22px;
    display: flex;
    cursor: pointer;
    border: none;

    @media (min-width: 600px) {
      width: 250px !important;
    }

    &:hover {
      color: var(--link-color);
      border: none;
    }

    &List {
      @media (min-width: 600px) {
        width: 220px !important;
      }
    }

    &Active {
      color: var(--link-color);
    }
  }
}

.subTab {
  display: flex;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  &Item {
    position: relative;
    border-radius: 5px;
    border: 2px solid $color-white-darker;
    padding: 6px 14px 7px 16px;
    height: 38px !important;
    background: #f0f3f6;
    z-index: 0;
    display: flex;
    align-items: center;
    &Active {
      background: $color-white;
      z-index: 1;
    }
  }
  &Item + &Item {
    margin-left: -5px;
  }
}

.showBorder {
  border-bottom: 1px solid $color-white-darker;
  padding: 6px 14px 7px 16px;
}

.dashboardChartContainer {
  position: relative;
}

.chartFilterContainer {
  position: absolute;
  top: 8px;
  right: 0px;
  display: flex;
  gap: 20px;
}
.hideFilterSelect {
  z-index: -1;
}

.scenarioTab {
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    color: $color-link;
    border: none;
  }

  &Container {
    padding: 10px;
    width: 500px;
    margin-top: -50px;
  }
  &List {
    top: 30px !important;
  }
}
.scenarioSubTab {
  margin-left: -10px !important;
}

.comparisionTableContainer {
  table {
    thead {
      th:first-child {
        position: sticky;
        left: -1px;
        width: 130px !important;
        z-index: 2;
      }
    }

    tbody {
      td:first-child {
        width: 130px !important;
      }
    }
  }
}
.scenarioComparisionName {
  color: var(--primary-color);
  cursor: pointer;
}

.teamSearchFilter {
  position: relative;
  flex: 2;
  margin-top: -3px;
  max-width: 500px;
  input {
    padding: 12px 10px 12px 50px;
    ::placeholder {
      color: $color-gray-dark;
    }
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    color: $color-gray-dark;
  }
}

.tableContainerMinHeight {
  min-height: 530px;
}

.benchmarkingPopupFooter {
  display: flex;
  justify-content: end;
  margin-top: 30px;
}
.cell {
  width: 100%;
}
