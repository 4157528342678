//**** Input fields ****//
input,
textarea {
  &:disabled {
    background-color: rgba($color-gray-light, 0.2);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--primary-color);
    border-radius: 2px;
  }

  &:invalid {
    border: 1px solid $color-error;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $color-white inset;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color-gray-light;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $color-gray-light;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: $color-gray-light;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: $color-gray-light;
  }
}

textarea {
  line-height: 1.4;
}

input[type='search'] {
  -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

//**** Select fields ****//
.selectContainer {
  position: relative;

  &:after {
    /* down arrow */
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    z-index: 0;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $color-gray-dark;
  }
}

.selectDisabled {
  opacity: 0.5;
}

select {
  /* remove all default styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  box-sizing: border-box;
  background: transparent;
  border: none;
  position: relative;
  z-index: 10;
  padding-right: 35px !important;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}

.selectPlaceholder {
  color: $color-gray;
}

//**** Radio fields ****//
.radioContainer {
  margin-bottom: 10px;

  label {
    cursor: pointer;
  }

  span {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    color: $color-text;
    padding-left: 30px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 2px solid $color-white-darker;
    }

    &:before {
      content: '';
      position: absolute;
      left: 5px;
      top: 5px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: transparent;
    }
  }

  input {
    display: none;

    &:checked + span:after {
      border: 2px solid var(--primary-color);
    }
    &:checked + span:before {
      background-color: var(--primary-color);
    }
  }
}

//**** Checkbox fields ****//
.checkboxContainer {
  span {
    position: relative;
    display: block;
    height: 20px;
    width: 20px;
    padding-left: 28px;
    margin: 10px 0;
    cursor: pointer;

    &:before {
      content: '\e5ca';
      position: absolute;
      left: 3px;
      top: 4px;
      font-size: 14px;
      z-index: 20;
      font-family: 'Material Icons';
      font-weight: 900;
      display: none;
      color: white;
    }

    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      height: 20px;
      width: 20px;
      background: lightgray;
    }
  }

  input {
    display: none;
    margin-top: 0;

    &.checked + span:after {
      background-color: var(--primary-color);
    }
    &.checked + span:before {
      display: inline-block;
    }
  }
}

//**** File input fields ****//
.fileInputContainer {
  input[type='file'] {
    display: none;
    margin: 10px;

    &:active + span {
      color: var(--link-color);
      border: 2px solid var(--link-color);
    }
  }

  span {
    display: inline-block;
    width: auto;
  }
}

//**** Shared Styles ****//
input,
textarea,
select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  color: $color-text;
  font-family: $font-primary;
}

input,
textarea,
.selectContainer {
  border: 1px solid $color-white-darker;
  border-radius: 2px;
  background-color: $color-white;
}
