@import '../../../../../../styles/main.scss';
.chartSettings {
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  &Select {
    margin: 0 16px 16px 0;
  }

  button {
    width: 90px;
    height: 51px;
  }
}
