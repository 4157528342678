@import './Modal.scss';
@import '../../styles/components/tabs.scss';
@import '../../styles/main.scss';
@import '../../styles/components/extras.scss';

.templates {
  padding: 20px;

  .tabs {
    margin-bottom: 20px;
    padding-top: 0;

    .tab {
      margin: 0 20px;
    }
  }

  &Heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    h2 {
      display: inline-block;
      margin-top: 0;
      font-size: 20px;
    }
  }

  &TOCWarning {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--secondary-color), 0.6);
    display: flex;
    justify-content: center;
    align-items: center;

    &Inner {
      max-width: 750px;
      margin: 20px;
      padding: 20px;
      box-shadow: 0 0 110px #313131;
      border-radius: 2px;
      background-color: $color-white;
      text-align: center;
    }

    img {
      max-width: 100%;
    }

    button {
      width: auto;
      margin: 0 auto 10px;
    }
  }

  &YearModal {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--secondary-color), 0.6);
    display: flex;
    justify-content: center;
    align-items: center;

    &Inner {
      max-width: 750px;
      min-width: 400px;
      max-height: 90vh;
      overflow-y: auto;
      margin: 20px;
      padding: 20px;
      box-shadow: 0 0 110px #313131;
      border-radius: 2px;
      background-color: $color-white;
      text-align: center;
      &Buttons {
        margin-top: 30px;
        display: flex;
        column-gap: 10px;
      }
    }

    img {
      max-width: 100%;
    }

    button {
      width: 100%;
      margin: 0 0 10px;
    }
  }
}

.extras {
  display: inline-block;
  background-color: unset !important;

  &Dropdown {
    width: 250px;
  }

  &Link {
    i {
      color: #000000;
    }
  }
}

.customMessage {
  width: 100%;
  height: 100px;
  padding: 10px;
  overflow: scroll;
  border: 1px solid $color-white-darker;
  outline: none;

  &:focus {
    border: 1px solid var(--primary-color);
  }
}

.templatesList {
  text-align: left;
  width: 100%;
  border-spacing: 0;

  &Single {
    padding: 18px;

    @media (min-width: 700px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:nth-child(2n) {
      background-color: $background;
    }
  }

  &Name {
    @media (min-width: 700px) {
      width: calc(100% - 260px);
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: normal;
      text-align: center;
      white-space: inherit;
      display: inline;
      text-align: left;
    }
  }

  &Links {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: inline-block;
      width: auto;
      margin: 10px 10px 0 0;

      @media (min-width: 700px) {
        margin: 0 0 0 10px;
      }
    }

    @media (min-width: 700px) {
      width: calc(340px);
      text-align: right;
    }
  }
}

.reportSelect {
  min-width: 150px;
}

.yearRange {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.targetSelect {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.generateModalOuter {
  overflow: unset;
}

.reportsTabContent {
  display: flex;
  justify-content: center;
  overflow: auto;
  max-height: calc(100vh - 240px);
  min-height: calc(100vh - 300px);
  overflow-y: inherit !important;
}

.nycBuildingSync {
  padding: 18px;

  h3 {
    margin-top: -18px;
  }
  &Download {
    button {
      margin-left: 0px;
      width: 148px;
    }
  }
}

.proposalContainer {
  text-align: left;

  &Label {
    margin-bottom: 20px;
  }
}

.proposalRadioContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  &Label {
    margin-bottom: 12px;
  }
}

.proposalSelector {
  display: flex;
  margin-bottom: 12px;

  &Label {
    color: $color-success;
    padding-left: 20px !important;
    cursor: pointer;
  }
}
