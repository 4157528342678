@import '../../../../styles/main.scss';

:global {
    .qlContactTag {
        background-color: var(--primary-color);
        padding: 2px 3px;
        border-radius: 3px;
        display: inline-block;
        width: auto;
        color: #fff;
    }

    .qlContactTag span{
        display: none;
    }

    .qlContactTag:before {
        content: attr(data-label)
    }
}
