@import '../../../../../../styles/main.scss';

.layoutSettings {
  display: flex;
  align-items: flex-end;

  &Select {
    margin: 0 16px 16px 0;
  }

  &ToggleTab {
    margin: 0 16px 16px 0;
    &Item {
      height: 46px;
    }
  }
}
