@import '../../../../../../styles/main.scss';

.container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.subContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
}

.subHeader {
    font-size: 16px;
    font-weight: bold;
}

.styleContainer {
    display: flex;
    align-items: center;
    gap: 20px;
}

.selectContainer {
    display: flex;
    flex: 1;
    select {
      font-size: 13.3333px;
    }
  }

.modal {
    .modalInner {
      min-width: 600px;
  
      .modalHeading {
        font-size: 18px;
        font-weight: bold;
      }
      .modalBody {
        height: calc(100vh - 200px);
        text-align: left;
        overflow: scroll;
        p {
          margin-bottom: 0;
          text-align: left;
        }
      }
      .modalFooter {
        float: right;
      }
    }
  }
  .button {
    width: 100px;
    margin: 0px;
    padding: 13px;
  }