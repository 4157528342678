@import '../../../../../../styles/main.scss';

.timestampContainer {
    display: flex;
    font-weight: normal;
    font-size: 16px;
    align-items: center;
    margin-left: 15px;
}

.checkboxContainer {
    display: flex;
    label {
        margin-right: 0px;
        span {
            margin: 0px;
        }
    }
}