@import '../../styles/main.scss';
@import './Modal.scss';

.imageModal {
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: $color-modal-background;

  &Inside {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    width: fit-content;
    height: 100%;
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 60px);
    margin: auto;
    padding: 20px;
    background: white;
  }

  &Close {
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 5px 9px;
    background-color: $color-gray;
    color: $color-white;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
