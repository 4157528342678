@import '../../../../styles/main.scss';
.reportList {
  display: block;
  text-align: left !important;
  div {
    clear: both;
    display: block;
    width: 100%;
    &.customYear {
      display: flex;
      width: fit-content;
      padding: 35px 0px 0px 0px;
      label {
        padding: 0px 80px 0px 70px;
      }
    }
  }
  button {
    width: 20%;
    float: right;
    margin-left: 10px;
  }
  label {
    display: block;
    padding-top: 15px;
    input {
      display: inline-block;
      width: auto;
      margin-right: 5px;
    }
    span {
      display: inline-block;
      width: auto;
    }
  }
  .attrList {
    margin-top: 15px;
    display: flex;
    width: fit-content;
    span {
      margin-right: 10px;
      padding: 5px 5px;
      background-color: var(--primary-color);
      width: 80px;
      text-transform: capitalize;
      a {
        float: right;
      }
    }
  }
  .fieldSpace {
    display: flex;
    .fields {
      border: 1px black solid;
      width: 30%;
      margin: 20px 20px;
      padding: 20px 20px;
    }
    .delete {
      float: right;
    }
  }
  .dropdownContainer {
    display: block;
    background: transparent;
    width: 100%;
    font-family: Muli, Source Sans Pro, sans-serif;
    border-radius: 2px;
    cursor: pointer;
    border: 1px lightgray solid;
  }
  .tabArrow {
    display: inline-block;
    position: relative;
    clear: both;
    height: initial;
    background: white;
    margin-top: 15px;

    &:after {
      /* down arrow */
      content: '';
      position: absolute;
      top: 50%;
      right: 3%;
      transform: translateY(-50%);
      z-index: 0;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #2c3444;
    }
  }

  .yearRange {
    width: 200px;
  }
}
.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
  iframe {
    margin: 20px 20px;
    width: calc(100% - 40px);
  }
  a {
    float: right;
  }
}
.error {
  margin-top: 10px;
  text-align: center;
  color: $color-red;
  font-size: 20px;
}

.showDirectionRow {
  flex-direction: column !important;
}
