@import '../../styles/main.scss';
@import '../../styles/components/extras.scss';

.building {
  padding-bottom: 40px;
  min-height: $minusHeader;

  &Loading {
    position: fixed;
    bottom: 50px;
    left: 0px;
    right: 0px;
    width: 100%;
    font-size: 40px;
    text-align: center;
  }

  &Header {
    padding: 10px 0;
    background-color: $color-white;
    border-bottom: 1px solid $color-white-darker;

    .container {
      @media (min-width: 550px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &Totals {
    h2 {
      cursor: pointer;
    }

    .extrasDropdown {
      top: calc(100% + 10px);
      width: 230px;
    }
  }

  &Buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;

    @media (max-width: 550px) {
      font-size: 16px;

      &Add {
        width: 100%;
      }

      &MoreList {
        width: 100%;
        margin:0;

        .extrasButton {
          width: 100%;
          text-align: center;
          padding-left: 5%;
        }
      }
    }

    &Add {
      i {
        margin-right: 10px;
      }

      span {
        display: none;

        @media (min-width: 600px) {
          display: inline;
        }
      }
    }

    &More &MoreList {
      margin-left: 10px;
    }
  }

  .extrasButton {
    margin: 10px 0;
  }
}
.headerBack {
  font-size: 22px;
  color: rgb(44, 52, 68);
}

.archivedHeader {
  display: flex;
  align-items: center;
  &Back {
    text-align: left;
    font-size: 14px;
    color: $color-gray;
    cursor: pointer;
    display: flex;

    i {
      margin-right: 10px;
      animation-duration: 1000ms;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      transition: 300ms;
    }

    &:hover {
      i {
        animation-name: bounce;
      }
    }

    @keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateX(0);
      }
      40% {
        transform: translateX(-6px);
      }
      60% {
        transform: translateX(-2px);
      }
    }
  }
}