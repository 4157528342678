@import '../../../../styles/main.scss';

.target {
  // main options section
  &__options {
    margin-top: 40px;

    h3 {
      margin-bottom: 0;
    }
  }

  // select box
  &__select {
    span {
      display: block;
      margin-bottom: 10px;
    }
  }

  // wrapper around input/radio/checkboxes
  &__inputs {
    display: flex;
    flex-wrap: wrap;

    h3 {
      width: 100%;
      margin: 40px 0 0;
    }

    &:first-child {
      h3 {
        margin-top: 10px;
      }
    }
  }
  //layout selection
  &__layout {
    display: flex;
    margin-bottom: 15px;
    label {
      display: inherit;
      padding-right: 20px;
      padding-top: 15px;
      cursor: pointer;
      span {
        padding-left: 20px;
      }
    }
  }
  // input/radio/checkboxes
  &__input {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    width: 180px;
    margin: 10px 20px 0 0;

    input {
      display: none;
      margin-top: 0;

      &.checked + span:after {
        background-color: var(--primary-color);
      }
      &.checked + span:before {
        display: inline-block;
      }
    }

    p {
      font-style: italic;
      font-size: 14px;
    }

    span {
      position: relative;
      display: block;
      width: 100%;
      padding-left: 28px;
      margin: 10px 0;

      &:before {
        content: '';
        position: absolute;
        z-index: 20;
        font-family: 'Material Icons';
        font-weight: 900;
        display: none;
        color: white;
      }

      &:after {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        height: 20px;
        width: 20px;
        background: lightgray;
      }
    }

    &--checkboxes {
      span {
        &:before {
          content: '\e5ca';
          left: 3px;
          top: 4px;
          font-size: 14px;
        }
      }
    }

    &--sub-checkboxes {
      span {
        font-style: normal;
        font-size: 15px;
        &:before {
          content: '\e5ca';
          left: 2px;
          top: -1px;
          font-size: 14px;
        }
      }
    }

    &--radio {
      span {
        &:before {
          content: '\e86c';
          left: 7px;
          top: 7px;
          font-size: 7px;
        }

        &:after {
          border-radius: 50%;
        }
      }
    }
  }

  // section for subfields
  &__sub-fields {
    margin-top: 40px;
  }

  // style selection
  &__list-style {
    margin-top: 40px;
    padding-top: 25px;
    border-top: 1px solid gray;

    label {
      cursor: pointer;
    }

    span {
      position: relative;
      padding-left: 30px;
      margin-right: 30px;

      &:before {
        content: '\e86c';
        position: absolute;
        z-index: 20;
        left: 7px;
        top: 7px;
        font-size: 7px;
        font-family: 'Material Icons';
        font-weight: 900;
        display: none;
        color: white;
      }

      &:after {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        height: 20px;
        width: 20px;
        background: lightgray;
        border-radius: 50%;
      }
    }

    input {
      display: none;
      margin-top: 0;

      &:checked + span:after {
        background-color: var(--primary-color);
      }
      &:checked + span:before {
        display: inline-block;
      }
    }
  }

  &__list-style_new {
    margin-top: 40px;
    padding-top: 25px;
    border-top: 0px solid gray;
    label {
      cursor: pointer;
    }
    span {
      position: relative;
      padding-left: 30px;
      margin-right: 30px;
      &:before {
        content: '\e86c';
        position: absolute;
        z-index: 20;
        left: 7px;
        top: 7px;
        font-size: 7px;
        font-family: 'Material Icons';
        font-weight: 900;
        display: none;
        color: white;
      }

      &:after {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        height: 20px;
        width: 20px;
        background: lightgray;
        border-radius: 50%;
      }
    }
    input {
      display: none;
      margin-top: 0;
      &:checked + span:after {
        background-color: var(--primary-color);
      }
      &:checked + span:before {
        display: inline-block;
      }
    }
  }
  &__table-type {
    display: block;
    margin-top: 5px;

    .radioContainer {
      display: flex;

      label {
        margin-right: 20px;
      }
    }
  }
}

.yearRange {
  width: 25%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.fieldNameContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customHeadingInput {
  border: 1px solid #e9edf1 !important;
  border-radius: 2px !important;
  background-color: #fff !important;
  display: block !important;
  width: 100% !important;
  padding: 12px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: $color-text !important;
  font-family: $font-primary !important;
}

.yearOption {
  padding: 10px 0px;
  border-top: 1px solid gray;

  label {
    cursor: pointer;
  }

  span {
    position: relative;
    padding-left: 30px;
    margin-right: 30px;

    &:before {
      content: '\e86c';
      position: absolute;
      z-index: 20;
      left: 7px;
      top: 7px;
      font-size: 7px;
      font-family: 'Material Icons';
      font-weight: 900;
      display: none;
      color: white;
    }

    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      height: 20px;
      width: 20px;
      background: lightgray;
      border-radius: 50%;
    }
  }

  label {
    display: unset !important;
    &.displayBlock {
      display: block !important;
    }
  }

  input {
    display: none !important;
    margin-top: 0;

    &:checked + span:after {
      background-color: var(--primary-color);
    }
    &:checked + span:before {
      display: inline-block;
    }
  }
}

.selectWidth {
  width: 35%;
  margin-bottom: 10px;
}


.labelCheckobx {
  display: flex !important;
  align-items: center !important;
}

.subCheckboxMargin {
  margin-left: 50px !important;
}

.collapsibleContainerSection {
  margin-left: 0px !important;
  width: 100% !important;
}

.collapsibleSection {
  margin-left: 0px !important;
}
