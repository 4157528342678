@import '../../../styles/main.scss';

.checkboxContainer {
  .label {
    display: block;
    width: fit-content;
  }
  span {
    width: fit-content;
  }
}
