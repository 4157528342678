@import '../../../styles/main.scss';

.quill {
  > div:last-child {
    font-family: $font-primary;
    > div:first-child {
      -webkit-user-select: text;
      &:before {
        display: flex;
        align-items: center;
        min-height: 24px;
      }
    }
  }
}

.disabled {
  background-color: #f3f4f4;
}

.personalizeButton {
  font-family: $font-primary;
  color: var(--primary-color);
  width: 120px !important;
  font-size: 14px;
  letter-spacing: 0.2px;
  text-transform: none !important;
  font-weight: bold;
  &:hover {
    color: var(--primary-color) !important;
    opacity: 0.8 !important;
  }
  &:focus {
    color: var(--primary-color) !important;
  }
}

.quilContainer {
  position: relative;
  word-break: break-word;
}
