.customLegend {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 100%;

  table {
    border-collapse: collapse;
    padding: 0px 10px;
    width: auto;

    tbody {
      padding: 0;
      margin: 0;

      tr {
        font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
        font-size: 14px;
        color: rgb(124, 124, 124);

        td {
          padding: 4px;
          padding-right: 10px;
          min-width: max-content;
          background-color: #ffffff;
          vertical-align: top;

          &.value {
            text-align: right;
          }

          span {
            display: inline-block;

            &.color {
              width: 14px;
              height: 14px;
              border-radius: 50%;
              margin-top: 2px;
            }
          }
        }
      }
    }
  }
}
