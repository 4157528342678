@import '../../../../../../styles/main.scss';

.chartPreview {
  display: block;
  background-color: #ffffff;
  &DonutOrPie {
    display: flex;
    &Left {
      flex-direction: row-reverse;
    }
    &Right {
      flex-direction: row;
    }
    &Top {
      flex-direction: column-reverse;
      padding-top: 20px;
    }
    &Bottom {
      flex-direction: column;
      padding-bottom: 20px;
    }
  }
}

.loaderSpinner {
  &CenteredPosition {
    position: absolute;
    top: 50%;
    margin-top: -30px;
    right: 50%;
    margin-right: -30px;
  }

  &Centered {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
