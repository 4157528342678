@import '../../../../../../styles/main.scss';

.collapsibleContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    h3 {
        font-size: 16px;
        font-weight: bold;
    }
}

.selectContainer {
    select {
      padding: 10px;
      padding-right: 20px !important;
    }

    &:after {
      right: 6px;
    }
}

.titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    white-space: nowrap;
}

.subTitle {
    font-size: 16px;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.checkboxContainer {
    display: flex;
    label {
        margin-right: 5px;
    }
}

.projectCheckboxContainer > div {
    display: inline-block;
    width: 100%;
    background-color: #f8fbff;
    padding: 15px;

    > label {
        display: inline-block;
    }
}

.collapsibleContainerSection {
    margin-left: 0px;
}

.collapsibleSection {
    margin-left: 0px;
}

.fieldSelector {
    margin-bottom: 10px;
    &Title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    &Description {
        font-size: 16px;
    }
  }

  .fieldContainer {
    position: relative;
    display: inline-flex;
    width: 100%;
    min-height: 30px;

    &Item {
      max-width: calc(100% - 100px);
    }

    &Clear {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 80px;
      color: var(--primary-color);
      font-size: 12px;
      text-decoration: underline;
      cursor: pointer;
    }
  }