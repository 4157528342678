@import '../../../styles/main.scss';

.editor {
  margin-top: 20px;
  &__header {
    margin-bottom: 2em;
    :first-child {
      margin-right: 10px;
    }
    @media (min-width: 600px) {
      display: flex;
      flex-wrap: wrap;
      :first-child {
        margin-right: 30px;
      }
    }
    
  }

  &__error {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 10px;
    padding: 5px;
    background-color: $color-error;
    color: $color-white;
    font-size: 12px;
  }

  &__delete {
    width: 100%;
    clear: both;

    span {
      display: block;
      margin: 20px 0 10px;
      font-size: 18px;
    }

    button {
      display: inline-block;
      width: auto;
      margin-right: 10px;
    }
  }

  &__name {
    @media (min-width: 600px) {
      flex: 1;
    }
  }
  &__edit {
    button {
      display: inline;
      width: auto;
      margin-right: 10px;

      @media (min-width: 600px) {
        margin: 0 0 0 10px;
      }
    }
  }

  &__panels {
    position: relative;
    border: 1px solid $color-gray-lightest;
    min-height: 1500px;

    @media (min-width: 800px) {
      display: flex;
      justify-content: space-between;
    }
  }
  &Label {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.editor-builder {
  background-color: $color-gray;

  > div {
    overflow: hidden;
  }

  > span {
    display: none;
  }

  &__scroll {
    position: relative;
    overflow-y: scroll;
    width: calc(100% + 20px);

    > h3 {
      margin: 0;
      padding: 20px;
      color: white;
      background-color: darken($color-gray, 5%);

      &:not(:first-child){
        margin-top: 20px;
      }
    }
  }

  &__config {
    padding: 10px 20px 20px;
    color: $color-white;
  
    label {
      position: relative;
      display: block;
      margin: 15px 0 0;
      padding-left: 26px;
      cursor: pointer;

      &:before {
        content: "\e5ca";
        position: absolute;
        display: none;
        z-index: 20;
        left: 3px;
        top: 3px;
        font-size: 14px;
        font-family: 'Material Icons';
        font-weight: 900;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 2px solid $color-white;
      }

      &.checked:before {
        display: inline-block;
      }
    }

    &--number-position {
      
      label {
        margin-left: 25px;

        &:after {
          border-radius: 50%;
        }
      }
    }

    &--toc-depth {
      input.depthInput {
        display: inline-block;
      }
    }
  
    input {
      display: none;
    }
  }

  .active {
    position: fixed;
    top: 0;
    width: 180px;
  }

  &__scroll-label {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    color: white;
    box-shadow: 0px -4px 7px -2px lighten($color-black, 10%);

    i {
      margin-right: 5px;
    }
  }

  @media (min-width: 800px) {
    width: 180px;
  }
}

.editor-body {
  padding: 15px;
  
  @media (min-width: 800px) {
    padding: 30px;
  }

  > div:nth-child(2) { // main body section
    position: relative;
    padding: 10px 0;
    min-height: 350px;

    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      height: calc(100% - 20px);
      width: 100%;
      opacity: 0.2;
      border: dashed 2px #000;  
    }

    &:after {
      content: 'Body';
      position: absolute;
      z-index: -1;
      display: block;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
      color: black;
      text-transform: uppercase;
      text-align: center;
      font-size: 40px;
      opacity: 0.2;
    }
  }

  &.white {
    > div:nth-child(2) {
      &:before {
        opacity: 0.05;
      }
      &:after {
        display: none;
      }
    }
  }

  @media (min-width: 800px) {
    width: calc(100% - 200px);
  }
}

.reportUploadStyles {
  margin-bottom: 20px;

  h3 {
    margin-bottom: 5px;
  }

  label {
    p {
      display: inline-block;
      width: auto;
      margin-left: 30px;
      padding: 12px 16px;
      border: 1px dashed #17253f;
      cursor: pointer;
    }

    input {
      display: none;
    }
  }

  .uploadedFile {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    p {
      margin-left: 30px;
      font-weight: bold;
    }
    span, a {
      margin-left: 20px;
      color: var(--link-color);
      cursor: pointer;
    }
    a {
      text-decoration: none;
    }
  }

  .templateDescription {
    display: flex;
    margin: 5px 0px;
    align-items: center;
    a {
      margin-left: 5px;
      color: var(--link-color);
      cursor: pointer;
      text-decoration: none;
    }
  }
}
.nameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  &Title {
    font-size: 32px;
  }
}
.editorHeader {
  padding: 10px 0;
  background-color: $color-white;
  border-bottom: 1px solid $color-white-darker;
}