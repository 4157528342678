@import '../../../../../../styles/main.scss';

.chartColors {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: end;
  column-gap: 20px;
  row-gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  margin-bottom: 20px;

  &Section {
    margin: 0;
    width: 100%;
  }

  &Button {
    position: relative;
    text-transform: none;
    width: auto !important;
    font-family: 'Muli', 'Source Sans Pro', sans-serif;
    color: #2c3444;
    letter-spacing: 0.2px;
    height: 46px !important;
    font-size: 16px;
    margin: 0 16px 16px 0;
    &:hover {
      color: var(--link-color);
    }
  }

  .checkboxContainer {
    padding-top: 10px;
    width: 100%;
    span {
      width: fit-content;
    }
  }
}
