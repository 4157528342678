@import '../../../styles/main.scss';
.compactColorInput {
  display: flex;
  column-gap: 10px;
  align-items: center;
  width: 180px;

  .color {
    min-height: 30px;
    min-width: 30px;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid #f9fafb;
  }

  .label {
    font-size: 12px;
    color: $color-gray;
    display: block;
    text-align: start;
  }
}
