@import '../../../styles/main.scss';

.imagePickerContainer {
  background-color: #fff;
  box-shadow: 0 0px 8px 0 $color-gray;
  width: 400px;
  &Header {
    font-size: 20px;
    min-height: 50px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-black;
    font-weight: bold;
  }
  &Footer {
    padding: 0px 20px 10px;
    display: flex;
    justify-content: space-between;
    > button {
      width: 30%;
      margin-left: 10px;
    }
  }
}

.square{
  &::after {
    position:absolute;
    top:-12px; 
    content: '';
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgba(0,0,0, 0.03);
  }
  &::before {
    position:absolute;
    top:-10px; 
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }
}

.squareLeft{
  @extend .square;
}

.squareCenter{
  @extend .square;
  &::after {
    left: calc(50% - 12px);
  }
  &::before {
    left: calc(50% - 12px);
  }
}

.squareRight{
  @extend .square;
  &::after {
    right: 0;
  }
  &::before {
    right: 0;
  }
}

.imageOptionsContainer {
    padding: 10px 20px 10px 20px;
    span {
      display: block;
      margin-bottom: 10px;
    }
}

.imageUploadContainer {
    padding-top: 10px;
    text-align: center;
    p {
        display: inline-block;
        width: auto;
        margin: 0;
        padding: 12px 16px;
        border: 1px dashed #17253f;
        cursor: pointer;
    }
  
    input {
      display: none;
    }
}

.imageConfigContainer {
    padding-top: 10px;
    display: flex;
    align-items: row;
}

.imageConfig {
    margin-left: 10px;
}

.imageContent {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 10px;

    img {
      max-width: 100%;
      max-height: 400px;
    }

    @media (min-width: 800px) {
      &:hover {
        > div:hover {
          i {
            opacity: 1;
          }
        }

        > div:after {
          opacity: 0.94;
        }

        i {
          opacity: 0.7;
        }
      }
    }
  }

.imageRemove {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    display: inline-block;
    width: auto;
    margin: 15px;
    padding: 7px 15px;
    font-size: 22px;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $color-white-dark;
      opacity: 0.94;
      transition: 250ms;
      box-shadow: 0px 0px 6px 0px var(--primary-color);
    }

    i {
      position: relative;
      z-index: 20;
      color: var(--primary-color);
      transition: 250ms;
      text-align: center;
      opacity: 1;
    }

    @media (min-width: 800px) {
      &:after {
        opacity: 0;
      }

      i {
        opacity: 0;
      }
    }
  }

  .buttonContainer {
    display: flex;
    button + button {
        margin-left: 10px;
    }
  }