@import '../../../styles/main.scss';

.multiLevelSelectContainer {
  label {
    font-size: 12px;
    margin-bottom: 5px;
    color: $color-gray;
    display: block;
  }
  .buttonStyle {
    min-height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 4px;
    border: 2px solid $color-gray-lightest;
    background-color: $color-white;
    padding: 10px 15px;
    border-radius: 30px;
    color: $color-black;
    font-size: 12px;
    margin: 0;
    text-transform: none;
    letter-spacing: 0;
    font-family: $font-primary;
    cursor: pointer;

    &.buttonDisable {
      color: $color-gray;
    }
  }

  .multiLevelSelect {
    position: relative;
    display: flex;
    align-items: center;
    height: 46px;
    border: 1px solid $color-white-darker;
    background: $color-white;
    min-width: 180px;
    max-width: 300px;
    margin: 0 16px 16px 0;

    cursor: pointer;

    &Overlay {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 21;
      cursor: auto;
    }
  }

  span {
    background-color: transparent;
    color: $color-text;
    text-align: left;
    font-size: unset;
    padding: 12px 35px 12px 12px;
    white-space: nowrap;
    overflow: hidden;
  }

  .placeholder {
    color: $color-gray;
  }

  .selectIcons {
    display: flex;
    align-items: stretch;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 10;
    pointer-events: none;
    font-size: 18px;
    background-color: $color-white;
    padding: 0 8px 0 3px;

    &-opened {
      i {
        transform: rotate(180deg);
      }
    }
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    width: 100%;

    .rightArrowContainer {
      margin-left: 10px;
    }
  }

  .active {
    color: var(--link-color);
  }

  .firstLevel {
    position: absolute;
    z-index: 22;
    top: calc(100% + 2px);
    left: 0;
    min-width: 100%;
    width: 100%;
    border-radius: 2px;
    background-color: $color-white;
    box-shadow: 0px 1px 8px 0px $color-gray;
    color: $color-black;
    max-width: 180px;
    word-break: break-word;

    li {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px 8px 10px;
      transition: color 200ms;
      cursor: pointer;

      div {
        display: flex;
      }

      i {
        color: $color-black;
        transition: color 200ms;
      }

      &:hover {
        color: var(--link-color);
      }
    }

    li:hover {
      color: var(--primary-color);
    }
  }

  .secondLevel {
    position: absolute;
    z-index: 22;
    top: 0;
    left: calc(100%);
    border-radius: 2px;
    background-color: $color-white;
    box-shadow: 0px 1px 8px 0px $color-gray;
    color: $color-black;
    min-width: 100px;
    max-width: 180px;
    width: max-content;
    word-break: break-word;

    li {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px 8px 10px;
      transition: color 200ms;
      cursor: pointer;

      div {
        display: flex;
      }

      i {
        color: $color-black;
        transition: color 200ms;
      }

      &:hover {
        color: var(--link-color);
      }
    }

    li:hover {
      color: var(--primary-color);
    }
  }

  .thirdLevel {
    position: absolute;
    z-index: 22;
    top: 0;
    left: calc(100%);
    border-radius: 2px;
    background-color: $color-white;
    box-shadow: 0px 1px 8px 0px $color-gray;
    color: $color-black;
    overflow-y: auto;
    min-width: 100px;
    width: max-content;
    max-width: 180px;
    word-break: break-word;

    li {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px 8px 10px;
      transition: color 200ms;
      cursor: pointer;

      div {
        display: flex;
      }

      i {
        color: $color-black;
        transition: color 200ms;
      }

      &:hover {
        color: var(--link-color);
      }
    }

    li:hover {
      color: var(--primary-color);
    }
  }
}
