@import '../../../styles/main.scss';

.dropdown {
  position: absolute;
  z-index: 1;
  top: calc(100% - 4px);
  right: 0;
  min-width: 180px;
  border-radius: 2px;
  background-color: $color-white;
  box-shadow: 0px 1px 8px 0px $color-gray;
  color: $color-black;

  &.wide {
    left: 0;
    right: auto;
    min-width: 300px;
  }

  span {
    display: block;
    padding: 15px 20px 0;
    font-size: 12px;
    color: $color-gray-light;
    text-transform: uppercase;
    border-top: 1px solid $color-gray-light;

    &:first-of-type {
      border-top: none;
    }

    &.empty {
      padding-top: 0;
    }
  }

  li {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    transition: color 200ms;
    cursor: pointer;

    div {
      display: flex;
      width: 100%;
    }

    i {
      margin-right: 10px;
      color: $color-gray-lightest;
      transition: color 200ms;

      &:nth-of-type(2) {
        margin-left: auto;
        margin-right: 0;
      }
    }

    &:hover {
      color: var(--link-color);

      i {
        color: var(--link-color);
      }
    }
  }

  li:hover {
    color: var(--primary-color);

    i {
      color: var(--primary-color);
    }
  }

  .loader {
    padding: 15px 20px;
  }
}

.link {
  @media (min-width: 800px) {
    position: relative;
    margin-right: 20px;
    padding: 20px 0;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
    }

    &.active {
      &:after {
        background-color: var(--primary-color);
      }
    }
  }
}

.link-active {
  color: var(--link-color);
}
